<template>
  <p>ForgotPassword</p>
</template>

<script>
export default {
  name: 'ForgotPassword',
};
</script>

<style scoped lang="scss"></style>
